export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  JSON: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  _id: Scalars['String']['output'];
  activities: Maybe<Array<Activity>>;
  avatar: Maybe<ImageData>;
  business: Maybe<Business>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  enabled: Maybe<Scalars['Boolean']['output']>;
  fcmToken: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  isAdmin: Maybe<Scalars['Boolean']['output']>;
  isOnline: Maybe<Scalars['Boolean']['output']>;
  isSubmitted: Maybe<Scalars['Boolean']['output']>;
  language: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  lastOnlineDate: Maybe<Scalars['DateTime']['output']>;
  ownerId: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  /** @deprecated We are going to have multiple roles for users so this field is deprecated */
  role: Maybe<UserRoles>;
  /** User could have multiple roles in order to interact with the system various ways */
  roles: Maybe<Array<UserRoles>>;
  settings: Maybe<AccountSetting>;
  status: Maybe<UserStatuses>;
  sub: Maybe<Scalars['String']['output']>;
  tags: Maybe<Array<Tag>>;
  travelCategories: Maybe<Array<TravelCategory>>;
  travelerCharacteristics: Maybe<Array<TravelerCharacteristic>>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  username: Maybe<Scalars['String']['output']>;
};

export type AccountList = {
  __typename?: 'AccountList';
  hasNext: Scalars['Boolean']['output'];
  items: Array<Account>;
  nextCreatedAt: Maybe<Scalars['DateTime']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type AccountSetting = {
  __typename?: 'AccountSetting';
  appLanguage: Maybe<Scalars['String']['output']>;
  countries: Maybe<Array<Country>>;
  currency: Maybe<Currency>;
  languages: Maybe<Array<Language>>;
  location: Maybe<LocationData>;
};

export type ActivitiesDetail = {
  __typename?: 'ActivitiesDetail';
  _id: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  emoji: Maybe<Scalars['String']['output']>;
  lang: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type Activity = {
  __typename?: 'Activity';
  _id: Scalars['String']['output'];
  color: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  details: Maybe<Array<ActivitiesDetail>>;
  index: Maybe<Scalars['Float']['output']>;
  parent: Maybe<Activity>;
};

export type BalanceOrder = {
  __typename?: 'BalanceOrder';
  amount: Scalars['Float']['output'];
  business: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  externalId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sender: Scalars['String']['output'];
  status: PaymentOrderStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type BalanceTransaction = {
  __typename?: 'BalanceTransaction';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  from: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceId: Scalars['String']['output'];
  paymentProvider: PaymentProvider;
  referenceId: Maybe<Scalars['String']['output']>;
  status: PaymentStatus;
  to: Maybe<Scalars['String']['output']>;
  transactionMetadata: Maybe<Scalars['JSON']['output']>;
  type: BalanceTransactionType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum BalanceTransactionType {
  COMMISSION = 'COMMISSION',
  RECHARGE = 'RECHARGE'
}

export type Business = {
  __typename?: 'Business';
  _id: Maybe<Scalars['String']['output']>;
  accounts: Maybe<Array<Account>>;
  address: Maybe<Scalars['String']['output']>;
  avatar: Maybe<ImageData>;
  /** Balance in minimum denomination of currency, curently in kopiyka only */
  balance: Maybe<Scalars['Float']['output']>;
  city: Maybe<Scalars['String']['output']>;
  companyName: Maybe<Scalars['String']['output']>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currencyRates: Maybe<Array<CurrencyRateData>>;
  description: Maybe<Scalars['String']['output']>;
  docs: Maybe<Array<ImageData>>;
  email: Maybe<Scalars['String']['output']>;
  ownerId: Maybe<Scalars['String']['output']>;
  /** Payment provider chosen for operation, currenly one only is possible */
  paymentProcessorName: Maybe<PaymentProcessorName>;
  /** Token required for processing requests on behalf of specific business, each business-type customer has to add it after completing onboarding process */
  paymentProcessorToken: Maybe<Scalars['String']['output']>;
  paymentProviderStatus: PaymentProviderStatus;
  phone: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  useCustomRates: Maybe<Scalars['Boolean']['output']>;
  website: Maybe<Array<Scalars['String']['output']>>;
  zip: Maybe<Scalars['String']['output']>;
};

export type BusinessList = {
  __typename?: 'BusinessList';
  hasNext: Scalars['Boolean']['output'];
  items: Array<Business>;
  nextCreatedAt: Maybe<Scalars['DateTime']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export enum BusinessStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REVIEW = 'REVIEW'
}

export type ConfigurePaymentProviderInput = {
  /** Once set, can not be reset to null */
  paymentProcessorName: PaymentProcessorName;
  /** Once set, can not be reset to null */
  paymentProcessorToken: Scalars['String']['input'];
};

export type Country = {
  __typename?: 'Country';
  _id: Maybe<Scalars['String']['output']>;
  bbox: Maybe<Array<Array<Scalars['Float']['output']>>>;
  country: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  name: Maybe<Scalars['String']['output']>;
  phoneCode: Maybe<Scalars['String']['output']>;
  translations: Maybe<TranslationData>;
};

export type CountryList = {
  __typename?: 'CountryList';
  hasNext: Scalars['Boolean']['output'];
  items: Array<Country>;
  nextCreatedAt: Maybe<Scalars['DateTime']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type CreateBusinessInput = {
  accounts: InputMaybe<Array<Scalars['String']['input']>>;
  address: InputMaybe<Scalars['String']['input']>;
  avatar: InputMaybe<ImageInput>;
  city: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  docs: InputMaybe<Array<ImageInput>>;
  email: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<BusinessStatus>;
  website: InputMaybe<Array<Scalars['String']['input']>>;
  zip: InputMaybe<Scalars['String']['input']>;
};

export type CreateNotificationInput = {
  entityId: InputMaybe<Scalars['String']['input']>;
  entityType: InputMaybe<NotificationEntityType>;
  imageUrl: InputMaybe<Scalars['String']['input']>;
  isBusiness: InputMaybe<Scalars['Boolean']['input']>;
  operationType: InputMaybe<NotificationOperationType>;
  owner: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<NotificationStatus>;
  text: InputMaybe<Scalars['String']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
};

export type CreateOfferAdditionInput = {
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  days: InputMaybe<Scalars['Float']['input']>;
  offerId: InputMaybe<Scalars['String']['input']>;
  price: InputMaybe<PriceDetailInput>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateOfferInput = {
  activities: Array<Scalars['String']['input']>;
  adults: Scalars['Float']['input'];
  children: InputMaybe<Scalars['Float']['input']>;
  days: Scalars['Float']['input'];
  description: Scalars['String']['input'];
  isCommissionIncluded: Scalars['Boolean']['input'];
  isPublic: InputMaybe<Scalars['Boolean']['input']>;
  location: OfferLocationInput;
  offerAddition: Array<OfferAdditionInput>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  photo: Array<ImageInput>;
  price: PriceDetailInput;
  priceEstimateType: OfferPriceEstimateType;
  requestId: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  status: OfferStatus;
  title: Scalars['String']['input'];
  type: OfferType;
};

export type CreateOrderInput = {
  additionalNotes: InputMaybe<Scalars['String']['input']>;
  offerAdditionId: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['String']['input'];
  persons: CreateOrderPersonInput;
  status: OrderStatus;
};

export type CreateOrderPersonInput = {
  adults: Scalars['Float']['input'];
  children: InputMaybe<Scalars['Float']['input']>;
  infant: InputMaybe<Scalars['Float']['input']>;
};

export type Currency = {
  __typename?: 'Currency';
  _id: Scalars['String']['output'];
  code: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  decimal_digits: Maybe<Scalars['Float']['output']>;
  enabled: Maybe<Scalars['Boolean']['output']>;
  isoCode: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  name_plural: Maybe<Scalars['String']['output']>;
  rounding: Maybe<Scalars['Float']['output']>;
  symbol: Maybe<Scalars['String']['output']>;
  symbol_native: Maybe<Scalars['String']['output']>;
};

export type CurrencyList = {
  __typename?: 'CurrencyList';
  hasNext: Scalars['Boolean']['output'];
  items: Array<Currency>;
  nextCreatedAt: Maybe<Scalars['DateTime']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type CurrencyRateData = {
  __typename?: 'CurrencyRateData';
  currencyA: Maybe<Currency>;
  currencyB: Maybe<Currency>;
  rate: Scalars['Float']['output'];
};

export type CurrencyRateInput = {
  currencyA: Scalars['String']['input'];
  currencyB: Scalars['String']['input'];
  rate: Scalars['Float']['input'];
};

export type CurrencyRateType = {
  __typename?: 'CurrencyRateType';
  currencyCodeA: Maybe<Scalars['Float']['output']>;
  currencyCodeB: Maybe<Scalars['Float']['output']>;
  date: Maybe<Scalars['Float']['output']>;
  rateBuy: Maybe<Scalars['Float']['output']>;
  rateCross: Maybe<Scalars['Float']['output']>;
  rateSell: Maybe<Scalars['Float']['output']>;
};

export type FilterInput = {
  items: InputMaybe<Array<FilterItemInput>>;
};

export type FilterItemInput = {
  fieldName: InputMaybe<Scalars['String']['input']>;
  filterOption: FilterOption;
  value: InputMaybe<Scalars['String']['input']>;
};

export enum FilterOption {
  Between = 'Between',
  Contains = 'Contains',
  Equals = 'Equals',
  In = 'In',
  NearBoxLocations = 'NearBoxLocations',
  NearLocations = 'NearLocations',
  NotContains = 'NotContains',
  NotEquals = 'NotEquals',
  NotIn = 'NotIn'
}

export type FindAllInput = {
  /** {key: "ASC" or "DESC" or "asc" or "desc" or 1 or -1} or {key: {direction: "ASC" or "DESC" or "asc" or "desc", nulls: "first" or "last" or "FIRST" or "LAST"}}} */
  order: InputMaybe<Scalars['JSON']['input']>;
  pagination: InputMaybe<IPagination>;
  where: InputMaybe<Scalars['JSON']['input']>;
};

export type GetBalanceOrderType = {
  __typename?: 'GetBalanceOrderType';
  count: Maybe<Scalars['Float']['output']>;
  data: Maybe<Array<BalanceOrder>>;
};

export type GetBalanceTransactionType = {
  __typename?: 'GetBalanceTransactionType';
  count: Maybe<Scalars['Float']['output']>;
  data: Maybe<Array<BalanceTransaction>>;
};

export type GetPaymentTransactionType = {
  __typename?: 'GetPaymentTransactionType';
  count: Maybe<Scalars['Float']['output']>;
  data: Maybe<Array<PaymentTransaction>>;
};

export type IPagination = {
  /** Started from 0 */
  page: Scalars['Int']['input'];
  /** Size of page */
  size: Scalars['Int']['input'];
};

export type ImageData = {
  __typename?: 'ImageData';
  bucket: Maybe<Scalars['String']['output']>;
  hash: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
};

export type ImageInput = {
  bucket: Scalars['String']['input'];
  hash: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  location: Scalars['String']['input'];
};

export type Language = {
  __typename?: 'Language';
  _id: Scalars['String']['output'];
  code: Scalars['String']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
};

export type LanguageList = {
  __typename?: 'LanguageList';
  hasNext: Scalars['Boolean']['output'];
  items: Array<Language>;
  nextCreatedAt: Maybe<Scalars['DateTime']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type LikeData = {
  __typename?: 'LikeData';
  requestId: Maybe<Scalars['String']['output']>;
  userId: Maybe<Scalars['String']['output']>;
};

export type LikeDataInput = {
  requestId: InputMaybe<Scalars['String']['input']>;
  userId: InputMaybe<Scalars['String']['input']>;
};

export type LocationData = {
  __typename?: 'LocationData';
  latitude: Scalars['String']['output'];
  longitude: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelOrder: Scalars['Boolean']['output'];
  /** Configure payment provider settings for business, available for owner/admin only */
  configurePaymentProvider: Scalars['Boolean']['output'];
  confirmOrderPayment: Scalars['Boolean']['output'];
  createBusiness: Business;
  createNotification: Notification;
  createOffer: Offer;
  createOfferAddition: OfferAddition;
  createOrder: Order;
  deleteOffer: Scalars['Boolean']['output'];
  deleteOfferAddition: Scalars['Boolean']['output'];
  /** Initialize payment for Order by retrieving payment URL */
  getPaymentUrlForOrder: Scalars['String']['output'];
  /** Initialize payment for recharging business balance by retrieving payment URL */
  rechargeBalance: Scalars['String']['output'];
  setOnlineAccount: Scalars['Boolean']['output'];
  updateAccount: Scalars['Boolean']['output'];
  /** Update business related data, available for owner/admin only */
  updateBusiness: Scalars['Boolean']['output'];
  updateNotification: Scalars['Boolean']['output'];
  updateOffer: Offer;
  updateOfferAddition: OfferAddition;
  updateOfferAndOrderStatus: Scalars['Boolean']['output'];
  updateOrder: Scalars['Boolean']['output'];
};


export type MutationCancelOrderArgs = {
  id: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};


export type MutationConfigurePaymentProviderArgs = {
  id: Scalars['ID']['input'];
  input: ConfigurePaymentProviderInput;
};


export type MutationConfirmOrderPaymentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateBusinessArgs = {
  input: CreateBusinessInput;
};


export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};


export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


export type MutationCreateOfferAdditionArgs = {
  input: CreateOfferAdditionInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationDeleteOfferArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOfferAdditionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGetPaymentUrlForOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRechargeBalanceArgs = {
  input: RechargeBalanceInput;
};


export type MutationSetOnlineAccountArgs = {
  input: SetOnlineAccountInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateBusinessArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBusinessInput;
};


export type MutationUpdateNotificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateOfferArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOfferInput;
};


export type MutationUpdateOfferAdditionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOfferAdditionInput;
};


export type MutationUpdateOfferAndOrderStatusArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOfferInput;
};


export type MutationUpdateOrderArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderInput;
};

export type Notification = {
  __typename?: 'Notification';
  _id: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  entityId: Maybe<Scalars['String']['output']>;
  entityType: Maybe<NotificationEntityType>;
  imageUrl: Maybe<Scalars['String']['output']>;
  isBusiness: Maybe<Scalars['Boolean']['output']>;
  isRead: Maybe<Scalars['Boolean']['output']>;
  operationType: Maybe<NotificationOperationType>;
  owner: Maybe<Account>;
  status: Maybe<NotificationStatus>;
  text: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export enum NotificationEntityType {
  CHAT = 'CHAT',
  MODAL = 'MODAL',
  OFFER = 'OFFER',
  ORDER = 'ORDER',
  REQUEST = 'REQUEST'
}

export type NotificationList = {
  __typename?: 'NotificationList';
  hasNext: Scalars['Boolean']['output'];
  items: Array<Notification>;
  nextCreatedAt: Maybe<Scalars['DateTime']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export enum NotificationOperationType {
  DELETE = 'DELETE',
  INSERT = 'INSERT',
  UPDATE = 'UPDATE'
}

export enum NotificationStatus {
  CANCELLED = 'CANCELLED',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  CONFIRMED = 'CONFIRMED',
  PENDING_AGENT = 'PENDING_AGENT',
  PENDING_TRAVELER = 'PENDING_TRAVELER'
}

export type Offer = {
  __typename?: 'Offer';
  _id: Maybe<Scalars['String']['output']>;
  activities: Maybe<Array<Activity>>;
  adults: Maybe<Scalars['Float']['output']>;
  children: Maybe<Scalars['Float']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currentPrice: Scalars['Float']['output'];
  days: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  isBlocked: Maybe<Scalars['Boolean']['output']>;
  isCommissionIncluded: Maybe<Scalars['Boolean']['output']>;
  isPublic: Maybe<Scalars['Boolean']['output']>;
  like: Scalars['Boolean']['output'];
  likes: Maybe<Array<LikeData>>;
  location: Maybe<OfferLocation>;
  offerAddition: Maybe<Array<OfferAddition>>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['String']['output']>;
  photo: Maybe<Array<ImageData>>;
  /** Price in whole units (hryvnia) */
  price: Maybe<PriceDetail>;
  priceEstimateType: Maybe<OfferPriceEstimateType>;
  requestId: Maybe<Request>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  status: Maybe<OfferStatus>;
  title: Maybe<Scalars['String']['output']>;
  type: Maybe<OfferType>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type OfferAddition = {
  __typename?: 'OfferAddition';
  _id: Maybe<Scalars['String']['output']>;
  adults: Maybe<Scalars['Float']['output']>;
  children: Maybe<Scalars['Float']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currentPrice: Scalars['Float']['output'];
  days: Maybe<Scalars['Float']['output']>;
  offerId: Maybe<Offer>;
  price: Maybe<PriceDetail>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type OfferAdditionInput = {
  _id: InputMaybe<Scalars['ID']['input']>;
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  days: InputMaybe<Scalars['Float']['input']>;
  offerId: InputMaybe<Scalars['String']['input']>;
  price: InputMaybe<PriceDetailInput>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
};

export type OfferList = {
  __typename?: 'OfferList';
  hasNext: Scalars['Boolean']['output'];
  items: Array<Offer>;
  nextCreatedAt: Maybe<Scalars['DateTime']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type OfferLocation = {
  __typename?: 'OfferLocation';
  coordinates: Maybe<OfferLocationCoordinate>;
  description: Maybe<Scalars['String']['output']>;
  place_id: Maybe<Scalars['String']['output']>;
};

export type OfferLocationCoordinate = {
  __typename?: 'OfferLocationCoordinate';
  coordinates: Maybe<Array<Scalars['Float']['output']>>;
  type: Maybe<OfferLocationCoordinateType>;
};

export type OfferLocationCoordinateInput = {
  coordinates: InputMaybe<Array<Scalars['Float']['input']>>;
  type: InputMaybe<Scalars['String']['input']>;
};

export enum OfferLocationCoordinateType {
  POINT = 'POINT'
}

export type OfferLocationInput = {
  coordinates: OfferLocationCoordinateInput;
  description: Scalars['String']['input'];
  place_id: Scalars['String']['input'];
};

export enum OfferPriceEstimateType {
  ESTIMATE = 'ESTIMATE',
  FINAL = 'FINAL'
}

export enum OfferStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED',
  HOT = 'HOT'
}

export enum OfferType {
  PERSON = 'PERSON',
  TOUR = 'TOUR'
}

export type Order = {
  __typename?: 'Order';
  _id: Maybe<Scalars['String']['output']>;
  additionalNotes: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  endAt: Maybe<Scalars['DateTime']['output']>;
  /** External ID received from payment provider once starting processing */
  externalId: Maybe<Scalars['String']['output']>;
  /** Final price of the order in UAH kopecks */
  finalPrice: Maybe<Scalars['Float']['output']>;
  isRead: Maybe<Scalars['Boolean']['output']>;
  offer: Maybe<Offer>;
  offerOwner: Maybe<Account>;
  orderNumber: Maybe<Scalars['Float']['output']>;
  originalOffer: Maybe<Offer>;
  owner: Maybe<Account>;
  paymentStatus: Maybe<PaymentStatus>;
  persons: Maybe<OrderPersonData>;
  reasons: Maybe<Array<OrderReason>>;
  startAt: Maybe<Scalars['DateTime']['output']>;
  status: Maybe<OrderStatus>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type OrderList = {
  __typename?: 'OrderList';
  hasNext: Scalars['Boolean']['output'];
  items: Array<Order>;
  nextCreatedAt: Maybe<Scalars['DateTime']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type OrderPersonData = {
  __typename?: 'OrderPersonData';
  adults: Maybe<Scalars['Float']['output']>;
  children: Maybe<Scalars['Float']['output']>;
  infant: Maybe<Scalars['Float']['output']>;
};

export type OrderReason = {
  __typename?: 'OrderReason';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
};

export enum OrderStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW',
  PENDING_AGENT = 'PENDING_AGENT',
  PENDING_TRAVELER = 'PENDING_TRAVELER',
  REFUNDED = 'REFUNDED'
}

export type PaginationInput = {
  /** Number of items returned in query. */
  limit: InputMaybe<Scalars['Int']['input']>;
  /** Created at after which to fetch records. */
  nextCreatedAt: InputMaybe<Scalars['DateTime']['input']>;
  /** ID after which to fetch records. */
  nextId: InputMaybe<Scalars['String']['input']>;
  /** fieldName__order */
  sort: InputMaybe<Scalars['String']['input']>;
};

export enum PaymentOrderStatus {
  CONFIRMED = 'CONFIRMED',
  PENDING_TRAVELER = 'PENDING_TRAVELER',
  REFUNDED = 'REFUNDED'
}

export enum PaymentProcessorName {
  BASE = 'BASE',
  MONOBANK = 'MONOBANK',
  ROZETKAPAY = 'ROZETKAPAY'
}

export enum PaymentProvider {
  DEFAULT = 'DEFAULT',
  MONOBANK = 'MONOBANK',
  ROZETKAPAY = 'ROZETKAPAY'
}

export enum PaymentProviderStatus {
  ACTIVE = 'ACTIVE',
  BASE = 'BASE',
  IN_PROGRESS = 'IN_PROGRESS'
}

export enum PaymentStatus {
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  SUCCESS = 'SUCCESS'
}

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  from: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceId: Scalars['String']['output'];
  paymentProvider: Maybe<Scalars['String']['output']>;
  referenceId: Maybe<Scalars['String']['output']>;
  status: PaymentStatus;
  to: Maybe<Scalars['String']['output']>;
  transactionMetadata: Maybe<Scalars['JSON']['output']>;
  type: PaymentTransactionType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum PaymentTransactionType {
  BANK_TRANSFER = 'BANK_TRANSFER',
  PURCHASE = 'PURCHASE',
  RECHARGE = 'RECHARGE'
}

export type PriceDetail = {
  __typename?: 'PriceDetail';
  currency: Maybe<Currency>;
  value: Maybe<Scalars['Float']['output']>;
};

export type PriceDetailInput = {
  currency: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type Query = {
  __typename?: 'Query';
  account: Maybe<Account>;
  accounts: AccountList;
  balanceOrders: GetBalanceOrderType;
  balanceTransactions: GetBalanceTransactionType;
  business: Maybe<Business>;
  /** List all businesses, available for admin only */
  businesses: BusinessList;
  countries: CountryList;
  currencies: CurrencyList;
  getAccount: Maybe<Account>;
  getCurrency: Maybe<Currency>;
  getCurrencyRates: Array<CurrencyRateType>;
  languages: LanguageList;
  notifications: NotificationList;
  offer: Maybe<Offer>;
  offerAddition: Maybe<OfferAddition>;
  offerAdditions: Array<OfferAddition>;
  offers: OfferList;
  order: Order;
  orders: OrderList;
  paymentTransactions: GetPaymentTransactionType;
  tags: TagList;
  travelCategories: TravelCategoryList;
  whoAmI: Account;
};


export type QueryAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAccountsArgs = {
  filter: InputMaybe<FilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryBalanceOrdersArgs = {
  input: InputMaybe<FindAllInput>;
};


export type QueryBalanceTransactionsArgs = {
  input: InputMaybe<FindAllInput>;
};


export type QueryBusinessArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBusinessesArgs = {
  filter: InputMaybe<FilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryCountriesArgs = {
  pagination: InputMaybe<PaginationInput>;
};


export type QueryCurrenciesArgs = {
  filter: InputMaybe<FilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryGetAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCurrencyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLanguagesArgs = {
  pagination: InputMaybe<PaginationInput>;
};


export type QueryNotificationsArgs = {
  filter: InputMaybe<FilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryOfferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOfferAdditionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOffersArgs = {
  filter: InputMaybe<FilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  filter: InputMaybe<FilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryPaymentTransactionsArgs = {
  input: InputMaybe<FindAllInput>;
};


export type QueryTagsArgs = {
  pagination: InputMaybe<PaginationInput>;
};


export type QueryTravelCategoriesArgs = {
  pagination: InputMaybe<PaginationInput>;
};

export type RechargeBalanceInput = {
  amount: Scalars['Float']['input'];
};

export type Request = {
  __typename?: 'Request';
  _id: Maybe<Scalars['String']['output']>;
  adults: Maybe<Scalars['Float']['output']>;
  archiveOffers: Maybe<Array<Scalars['String']['output']>>;
  children: Maybe<Scalars['Float']['output']>;
  childrenAge: Maybe<Array<Scalars['Float']['output']>>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  date: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  isHidden: Maybe<Scalars['Boolean']['output']>;
  isRead: Maybe<Scalars['Boolean']['output']>;
  likedOffers: Maybe<Array<Offer>>;
  location: Maybe<RequestLocationData>;
  locationStart: Maybe<RequestLocationData>;
  nights: Maybe<Scalars['Float']['output']>;
  offersCount: Maybe<Scalars['Float']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['String']['output']>;
  periodDays: Maybe<Scalars['Float']['output']>;
  price: Maybe<PriceDetail>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  status: Maybe<RequestStatus>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  viewedAccounts: Maybe<Array<Scalars['String']['output']>>;
};

export type RequestLocationCoordinate = {
  __typename?: 'RequestLocationCoordinate';
  coordinates: Maybe<Array<Scalars['Float']['output']>>;
  type: Maybe<RequestLocationCoordinateType>;
};

export enum RequestLocationCoordinateType {
  Point = 'Point'
}

export type RequestLocationData = {
  __typename?: 'RequestLocationData';
  coordinates: Maybe<RequestLocationCoordinate>;
  description: Maybe<Scalars['String']['output']>;
  place_id: Maybe<Scalars['String']['output']>;
};

export enum RequestStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED'
}

export type SetOnlineAccountInput = {
  isOnline: Scalars['Boolean']['input'];
};

export type Tag = {
  __typename?: 'Tag';
  _id: Scalars['String']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  image: Maybe<ImageData>;
  index: Scalars['Float']['output'];
  name: Maybe<Scalars['String']['output']>;
  translations: Maybe<TranslationData>;
};

export type TagList = {
  __typename?: 'TagList';
  hasNext: Scalars['Boolean']['output'];
  items: Array<Tag>;
  nextCreatedAt: Maybe<Scalars['DateTime']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type TranslationData = {
  __typename?: 'TranslationData';
  de: Maybe<Scalars['String']['output']>;
  en: Maybe<Scalars['String']['output']>;
  uk: Maybe<Scalars['String']['output']>;
};

export type TravelCategory = {
  __typename?: 'TravelCategory';
  _id: Scalars['String']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  image: Maybe<ImageData>;
  index: Scalars['Float']['output'];
  title: Maybe<Scalars['String']['output']>;
  translations: Maybe<TranslationData>;
};

export type TravelCategoryList = {
  __typename?: 'TravelCategoryList';
  hasNext: Scalars['Boolean']['output'];
  items: Array<TravelCategory>;
  nextCreatedAt: Maybe<Scalars['DateTime']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type TravelerCharacteristic = {
  __typename?: 'TravelerCharacteristic';
  _id: Scalars['String']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  details: Maybe<Array<TravelerCharacteristicsDetail>>;
  index: Maybe<Scalars['Float']['output']>;
  parent: Maybe<TravelerCharacteristic>;
};

export type TravelerCharacteristicsDetail = {
  __typename?: 'TravelerCharacteristicsDetail';
  _id: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  lang: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type UpdateAccountInput = {
  activities: InputMaybe<Array<Scalars['String']['input']>>;
  avatar: InputMaybe<ImageInput>;
  business: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  fcmToken: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  role: InputMaybe<Scalars['String']['input']>;
  roles: InputMaybe<Array<UserRoles>>;
  settings: InputMaybe<UpdateAccountInputSettings>;
  tags: InputMaybe<Array<Scalars['String']['input']>>;
  travelCategories: InputMaybe<Array<Scalars['String']['input']>>;
  travelerCharacteristics: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateAccountInputSettings = {
  appLanguage: InputMaybe<Scalars['String']['input']>;
  countries: InputMaybe<Array<Scalars['String']['input']>>;
  currency: InputMaybe<Scalars['String']['input']>;
  languages: InputMaybe<Array<Scalars['String']['input']>>;
  location: InputMaybe<UpdateAccountInputSettingsLocation>;
};

export type UpdateAccountInputSettingsLocation = {
  latitude: Scalars['String']['input'];
  longitude: Scalars['String']['input'];
};

export type UpdateBusinessInput = {
  accounts: InputMaybe<Array<Scalars['String']['input']>>;
  address: InputMaybe<Scalars['String']['input']>;
  avatar: InputMaybe<ImageInput>;
  city: InputMaybe<Scalars['String']['input']>;
  companyName: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  currencyRates: InputMaybe<Array<CurrencyRateInput>>;
  description: InputMaybe<Scalars['String']['input']>;
  docs: InputMaybe<Array<ImageInput>>;
  email: InputMaybe<Scalars['String']['input']>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
  useCustomRates: InputMaybe<Scalars['Boolean']['input']>;
  website: InputMaybe<Array<Scalars['String']['input']>>;
  zip: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOfferAdditionInput = {
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  days: InputMaybe<Scalars['Float']['input']>;
  offerId: InputMaybe<Scalars['String']['input']>;
  price: InputMaybe<PriceDetailInput>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateOfferInput = {
  activities: InputMaybe<Array<Scalars['String']['input']>>;
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  days: InputMaybe<Scalars['Float']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  isCommissionIncluded: InputMaybe<Scalars['Boolean']['input']>;
  isPublic: InputMaybe<Scalars['Boolean']['input']>;
  likes: InputMaybe<Array<LikeDataInput>>;
  location: InputMaybe<OfferLocationInput>;
  offerAddition: InputMaybe<Array<OfferAdditionInput>>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  photo: InputMaybe<Array<ImageInput>>;
  price: InputMaybe<PriceDetailInput>;
  priceEstimateType: InputMaybe<OfferPriceEstimateType>;
  requestId: InputMaybe<Scalars['String']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<OfferStatus>;
  title: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<OfferType>;
};

export type UpdateOrderInput = {
  additionalNotes: InputMaybe<Scalars['String']['input']>;
  endAt: InputMaybe<Scalars['DateTime']['input']>;
  finalPrice: InputMaybe<Scalars['Float']['input']>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  offer: InputMaybe<Scalars['String']['input']>;
  offerOwner: InputMaybe<Scalars['String']['input']>;
  orderNumber: InputMaybe<Scalars['Float']['input']>;
  originalOffer: InputMaybe<Scalars['String']['input']>;
  owner: InputMaybe<Scalars['String']['input']>;
  persons: InputMaybe<UpdateOrderPersonInput>;
  reasons: InputMaybe<Array<UpdateOrderReasonInput>>;
  startAt: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<OrderStatus>;
};

export type UpdateOrderPersonInput = {
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  infant: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOrderReasonInput = {
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
};

export enum UserRoles {
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  MANAGER = 'MANAGER'
}

export enum UserStatuses {
  ARCHIVED = 'ARCHIVED',
  COMPROMISED = 'COMPROMISED',
  CONFIRMED = 'CONFIRMED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
  RESET_REQUIRED = 'RESET_REQUIRED',
  UNCONFIRMED = 'UNCONFIRMED',
  UNKNOWN = 'UNKNOWN'
}
